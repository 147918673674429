#StockCalendar {
  height: calc(100vh - var(--header-height) - var(--footer-height));
  margin-top: var(--header-height);
  margin-bottom: var(--footer-height);
  overflow-x: auto;
  padding: 0 120px;
}

#StockCalendar .month_controller {
  display: flex;
  justify-content: space-between;
  background-color: var(--thead-bg-color);
  height: 35px;
  margin-top: 20px;
  align-items: center;
  border: 1px solid var(--calendar-border-color);
  padding: 0 10px;
}

#StockCalendar .month_controller svg {
  fill: #666;
  width: 0.8em;
  vertical-align: middle;
}

#StockCalendar .month_controller .prev_month, #StockCalendar .month_controller .next_month {
  display: flex;
  align-items: center;
  cursor: pointer;
}
#StockCalendar .month_controller .prev_month svg {
  margin-left: 0.8em;
  margin-top: -2px;
}
#StockCalendar .month_controller .next_month svg {
  margin-right: 0.8em;
  margin-top: -2px;
}

#StockCalendar .calendar {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 0.8em;
}

#StockCalendar .calendar table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--calendar-border-color);
}

#StockCalendar .calendar table thead tr {
  height: 2.5em;
  background: #eee;
}

#StockCalendar .calendar table th, #StockCalendar .calendar table td {
  border: 1px solid var(--calendar-border-color);
}

#StockCalendar .calendar th.Sun, #StockCalendar .calendar th.Sat {
  width: 3%;
  min-width: 3em;
}
#StockCalendar .calendar th.weekday {
  width: 18%;
}
#StockCalendar .calendar th.Sun {
  color: red;
}
#StockCalendar .calendar th.Sat {
  color: blue;
}

#StockCalendar .calendar .Sun .date {
  color: red;
}
#StockCalendar .calendar .holiday .date {
  color: red;
}
#StockCalendar .calendar .Sat .date {
  color: blue;
}

#StockCalendar .calendar table td.today {
  background-color: #eee;
}

#StockCalendar .calendar table td {
  padding: 3px 6px;
  height: 8em;
}
#StockCalendar .calendar table td > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#StockCalendar .calendar table td a {
  color: black;
  text-decoration: none;
}
#StockCalendar .calendar table td a:hover {
  border-bottom: 1px solid black;
}

#StockCalendar .hidden {
  visibility: hidden;
}

@media screen and ( max-width: 1080px ) {
  #StockCalendar {
    padding: 0 10px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #StockCalendar .month_controller {
    font-size: 1.2em;
  }

  #StockCalendar .calendar table thead tr {
    height: 3.25em;
  }
}