#OfficialLandPricesDetail {
  font-weight: normal;
}

#OfficialLandPricesDetail > div {
  margin: 3em 4em;
}

#OfficialLandPricesDetail h3 {
  margin-top: 2em;
}
#OfficialLandPricesDetail .Chart {
  margin-top: 3em;
}

#OfficialLandPricesDetail table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  border: 0 solid #CCCCCC;
  font-size: 1.15em;
}

#OfficialLandPricesDetail table th, #OfficialLandPricesDetail table td {
  border: 1px solid #CCCCCC;
  padding: 10px;
}

#OfficialLandPricesDetail table th {
  background-color: #F4F4F4;
  border: 1px solid #CCCCCC;
  font-weight: bold;
}

#OfficialLandPricesDetail table.land_price_survey_details th {
  width: 50%;
}

#OfficialLandPricesDetail table.past_land_prices_table {
  text-align: center;
}
#OfficialLandPricesDetail table.past_land_prices_table th {
  width: 25%;
}

#OfficialLandPricesDetail .container {
  position: relative;
}

#OfficialLandPricesDetail .container .close_button {
  text-align: center;
  padding: 20px;
}
#OfficialLandPricesDetail .container .close_button button {
  background: #D9D9D9;
  padding: 5px 15px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
#OfficialLandPricesDetail .container .close_button button > div {
  display: flex;
  align-items: center;
}
#OfficialLandPricesDetail .container .close_button button > div > svg {
  margin-right: 0.5em;
}

#OfficialLandPricesDetail .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
#OfficialLandPricesDetail .spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
