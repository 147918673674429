#UsReit_Background {
  position: relative;
  width: 100%;
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
  background-size: cover;
}

#UsReit_Background > div {
  position: absolute;
  color: white;
  margin-left: 30px;
}
#UsReit_Background > div h1 {
  font-size: 2em;
}
#UsReit_Background > div h2 {
  font-size: 1.2em;
}
#UsReit_Background > div h2:first-of-type {
  margin-top: 3em;
}

#UsReit_Background #video-area {
  position: fixed;
  z-index: -1;  /*最背面に設定*/
  top: var(--header-height);
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
#UsReit_Background video {
  /*天地中央配置*/
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  /*縦横幅指定*/
  /* 16:9 の幅→16 ÷ 9＝ 177.77% */
  width: 177.77777778vh; 
  /* 16:9の幅 → 9 ÷ 16 = 56.25% */
  height: 56.25vw; 
  min-height: 100%;
  min-width: 100%;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #UsReit_Background > div h1 {
    font-size: 3em;
  }
  #UsReit_Background > div h2 {
    font-size: 1.8em;
  }
}
