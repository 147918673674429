#Stock {
  height: calc(100vh - var(--header-height) - var(--scroll-bar-height) - var(--footer-height));
  padding: 10px;
  padding-left: 40px;
  font-size: 0.85em;
  overflow-x: auto;
  margin-top: var(--header-height);
}

#Stock .top_button_area {
  margin-left: 30px;
  height: 32px;
}
#Stock .top_button_area.mobile {
  margin-left: 0;
}
#Stock .top_button_area button {
  background-color: rgb(25,50,75);
  border: 1px solid rgb(25,50,75);
  border-radius: 3px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  line-height: 2em;
  margin: 0 3px;
  padding: 0 1em;
}
#Stock .top_button_area .update_date {
  margin-left: 10px;
  vertical-align: middle;
}
#Stock .top_button_area .update_date.mobile {
  margin-left: 11px;
}

#Stock .twrapper {
  overflow-x: scroll;
  white-space: nowrap;
  height: calc(100vh - var(--header-height) - var(--footer-height) - var(--scroll-bar-height) - 32px);  /* ボタンエリア(32px) */
  position: relative;
}
#Stock .twrapper table .fixcell {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}
#Stock .twrapper table .fixcell:before {
  /* 列固定しても枠線が固定されない問題の対応 */
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  pointer-events: none;  /* checkboxにチェックを入れられるようにする。*/
}

#Stock table {
  border-collapse: collapse;
  width: auto;
}

/* 文言説明の吹き出しが最前面に表示されるようにするための設定(ここから) */
#Stock table thead {
  position: relative;
  z-index: 2;
}
#Stock table tbody {
  position: relative;
  z-index: 1;
}
/* 文言説明の吹き出しが最前面に表示されるようにするための設定(ここまで) */

#Stock table th, #Stock table td {
  padding: 5px 15px;
}

#Stock table th.checkbox, #Stock table td.checkbox {
  border: 1px solid white !important;
  background-color: white !important;
}
#Stock table tr:nth-child(odd) th, #Stock table tr:nth-child(odd) td {
  border: 1px solid white;
}
#Stock table tr:nth-child(even) th, #Stock table tr:nth-child(even) td {
  border: 1px solid #eee;
}

#Stock table th {
  position: relative;
  background-color: var(--thead-bg-color);
  user-select: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
  vertical-align: top;
  border-top: none !important;  /* 縦スクロールした際に、文字が重ならないように上部の罫線は引かない。 */
}

#Stock table thead tr th > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#Stock table thead tr:nth-child(1) th {
  /* 1段目 */
  height: 20px;
}

#Stock table thead tr:nth-child(2) th {
  /* 2段目 */
  top: 32px;
  vertical-align: top;
}
#Stock table thead th:before {
  /* 列固定しても枠線が固定されない問題の対応 */
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  border: 1px solid white;
}
#Stock table thead tr:nth-child(1) th:before {
  border-bottom: 1px solid var(--thead-bg-color) !important;
}
#Stock table thead tr:nth-child(2) th:before {
  border-top: 1px solid var(--thead-bg-color) !important;
}
#Stock table thead tr:nth-child(2) th.order_by:before {
  border-top: 1px solid #c0f8ff !important;
}
#Stock table thead tr:nth-child(1) th:first-child:before {
  border-bottom: 1px solid white !important;
  background: white;  /* 縦スクロール時に、チェックボックスの見出しの上側の隙間に文字が微かに見えてしまうのに対応。 */
}
#Stock table thead tr:nth-child(2) th:first-child:before {
  border-top: 1px solid white !important;
}
#Stock table thead tr:nth-child(1) th.has_second_heading:before {
  border-left: 3px solid var(--thead-bg-color) !important;
}
#Stock table thead tr:nth-child(2) th.has_second_heading:before {
  border-top: 1px solid white !important;
}

#Stock table thead tr th.has_second_heading {
  vertical-align: bottom;
  padding: 0;
}
#Stock table thead tr th.has_second_heading > div {
  border-left: 3px solid white;
  border-top: 1px solid white;
}

#Stock table td {
  background: #eee;
  z-index: 1;
  position: relative;
}
#Stock table td.has_not_hist_data {
  background: var(--bg-color) !important;
  border: none !important;
}
#Stock table td.center {
  text-align: center;
}
#Stock table td.right {
  text-align: right;
}

#Stock table tr:nth-child(odd) td {
	background: #fff;
}

#Stock table th.checkbox {
  z-index: 2 !important;
}
#Stock table th.checkbox, #Stock table td.checkbox {
  max-width: 10px!important;
  min-width: 10px!important;
  width: 10px!important;
  left: 0px;
  padding: 5px 11px 5px 10px;
}
#Stock table td.checkbox {
  z-index: 1 !important;
}
#Stock table td.checkbox input[type="checkbox"] {
  margin: 0;
  vertical-align: middle;
}

#Stock table th.sector {
  z-index: 2 !important;
}
#Stock table th.sector, #Stock table td.sector {
  left: 0px;
  white-space: nowrap;
}
#Stock table td.sector {
  z-index: 1 !important;
}

#Stock table td.data_ir button {
  background-color: #c45e4a;
  border: 1px solid #c45e4a;
  border-radius: 3px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  line-height: 1.5em;
  margin: 0 3px;
}
#Stock table td.data_ir button.nippon_building {
  background-color: #80350E;
  border: 1px solid #80350E;
}
#Stock table td.data_ir .button_area {
  display: flex;
  justify-content: space-between;
}
#Stock table td.data_ir .button_area.mobile {
  justify-content: center;
}
#Stock table td.data_ir button.top_margin {
  margin-top: 3px;
}

#Stock table td.portfolio button {
  background-color: rgb(25,50,75);
  border: 1px solid rgb(25,50,75);
  border-radius: 3px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  line-height: 1.5em;
  margin: 0 3px;
}
#Stock table td.portfolio .button_area {
  display: flex;
  justify-content: space-between;
}
#Stock table td.portfolio button.top_margin {
  margin-top: 3px;
}

#Stock table th.stock_name {
  z-index: 6 !important;
}
#Stock table th.stock_name, #Stock table td.stock_name {
  white-space: nowrap;
  left: -1px;  /* 横スクロールした際に、罫線部分に文字が重ならないように。 */
}
#Stock table td.stock_name {
  z-index: 5 !important;
}
#Stock table td.stock_name a:hover, #Stock table td.stock_price_E a:hover {
  border-bottom: 1px solid black;
}

#Stock table td.stock_name a:hover, #Stock table td.stock_price a:hover {
  border-bottom: 1px solid black;
}

#Stock table th.data_ir, #Stock table td.data_ir {
  padding: 5px;
}

#Stock table th.blank, #Stock table td.blank {
  background: var(--bg-color) !important;
  border: none !important;
  width: 2em;
}
#Stock table th.blank:before {
  border: none !important;
}

#Stock table td.cr_noi {
  text-align: center;
}

#Stock table td.na {
  text-align: center;
}

#Stock .sortable {
  cursor: pointer;
}

#Stock table th svg {
  padding-top: 3px;
}

#Stock .indicator_neutral {
  fill: #aaaaaa;
  fill-opacity: 1;
}
#Stock .indicator_asc, #Stock .indicator_dsc {
  fill: #000;
  fill-opacity: 1;
}
#Stock .indicator_orderBy_sector {
  fill: #000;
  fill-opacity: 1;
}

#Stock .order_by {
  background: #c0f8ff !important;
}

.width_adjust {
  width: fit-content;
}


#Stock .balloon_parent {
  /* position: relative; */
}
#Stock .balloon_parent .balloon {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  font-size: 0.9em;
  font-weight: normal;
  color: white;
  background-color: gray;
  border-radius: 6px;
  top: calc(100% + 15px);
}
#Stock .balloon_parent:hover .balloon {
  display: inline;
}
#Stock .balloon_parent .balloon.center {
  left: 50%;
  transform: translateX(-50%);
}
#Stock .balloon_parent .balloon.right {
  right: 1px;
}

#Stock .minus {
  color: red;
}

#Stock table td.stock_code {
  text-align: center;
}

#Stock table td.pie {
  position: relative;
}
#Stock table td.pie img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 18px;
}

#Stock table td a {
  color: inherit;  /* リンクの色を元の色に設定 */
  text-decoration: none;  /* リンクの下線を削除 */
}

#Stock .balloon {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  font-size: 0.9em;
  font-weight: normal;
  color: white;
  background-color: gray;
  border-radius: 6px;
  top: calc(100% + 15px);
  text-align: left;
}
#Stock table th:hover .balloon {
  display: inline;
}
#Stock table th .balloon.center {
  left: 50%;
  transform: translateX(-50%);
}
#Stock table th .balloon.left {
  left: 1px;
}
#Stock table th .balloon.right {
  right: 1px;
}
#Stock table th .balloon.nowrap {
  white-space: nowrap;
}
#Stock table th .balloon.wrap {
  white-space: break-spaces;
  width: 500px;
}

#Stock .pie_chart {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px grey;
  z-index: 9999;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #Stock {
    /* height: calc(100vh - 27px - 20px - 26px); */
    margin-top: calc(var(--header-height) + 1px);
  }  
  #Stock .twrapper {
    /* height: calc(100vh - 27px - 20px - 26px - 26px); */
  }
  #Stock .top_button_area {
    height: 26px;
  }
  #Stock .top_button_area .update_date {
    margin-left: 6px;
    vertical-align: unset;
  }
  #Stock .top_button_area .update_date.mobile {
    margin-left: 11px;
  } 
  
  #Stock table th, #Stock table td {
    padding: 3px 10px;
  }
  #Stock table thead tr th.has_second_heading {
    padding: 0;
  }

  #Stock table th.sector, #Stock table td.sector {
    width: 82px !important;
    max-width: 82px !important;
    min-width: 82px !important;
  }
  
  #Stock table th.stock_name, #Stock table td.stock_name {
    min-width: 160px; /*  org 195px  */
    max-width: 160px; /*  org 105px  */
  }  
}

@media screen and ( max-width: 1080px ) {
  #Stock {
    padding-left: 10px;
  }
}
