#StockData {
  margin-top: var(--header-height);
  margin-bottom: var(--footer-height);
  /* height: calc(100vh - var(--header-height) - var(--scroll-bar-height) - var(--footer-height) - 10px); */
  height: calc(100vh - var(--header-height) - var(--footer-height));
  overflow-x: auto;
}

#StockData .wrapper {
  padding: 10px 40px;
}

#StockData .row {
  display: flex;
}
#StockData .column {
  flex-direction: column;
}
#StockData .column:first-child {
  /* min-widthを変更する場合は、StockData.tsxのget_right_chart_width()のleft_widthも変更すること。 */
  min-width: 36%;
  padding-right: 10px;
}
@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #StockData .column:first-child {
    /* min-widthを変更する場合は、StockData.tsxのget_right_chart_width()のleft_widthも変更すること。 */
    min-width: 30%;
  }
}
#StockData .column:nth-child(2) {
  padding-left: 10px;
}

#StockData table {
  font-size: 0.95em;
  width: 100%;
}
#StockData table, #StockData tr, #StockData td {
  border-collapse: collapse;
  border: 1px solid #ccc;
  background-color: white;
}
#StockData table tr td:first-child {
  /* 幅を狭くして、nowrap指定で文字と同じ幅に調整させる。 */
  white-space: nowrap;
  width: 1px;
  padding-right: 20px;
}
#StockData table tr td:nth-of-type(2) {
  text-align: center;
}
#StockData table tr td {
  padding: 0 6px;
}
#StockData table tr.image_row {
  height: 284px;
}
#StockData table img {
  max-height: 230px;
  max-width: 90%;
  padding: 3px;
}

#StockData .Chart {
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 20px;
  padding: 10px 0;
}
#StockData .Chart:first-child {
  margin-top: 0px;
}

#StockData .Chart_NOIY_Occ {
  margin-top: 0 !important;
}
#StockData .Chart_ExpRatio, #StockData .Chart_ExpDetail {
  height: auto !important;
}
#StockData .Chart_ExpRatio .chart {
  margin-top: -20px;
}

#StockData .Chart .title {
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  position: relative;
  z-index: 1;
}
#StockData .Chart .rent_range {
  text-align: center;
  margin-top: 10px;
  margin-bottom: -20px;
  font-size: 0.8em;
}

#StockData .Chart > .reit_ave {
  margin-left: 1.5em;
  margin-bottom: 1em;
  font-size: 0.8em;
  font-weight: bold;
}
#StockData .Chart > .reit_ave ul {
  list-style: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

#StockData .Chart .wrapper {
  position: relative;
}
#StockData .Chart .wrapper .reit_ave {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8em;
  font-weight: bold;
  margin-top: -0.5em;
}
#StockData .Chart .wrapper .reit_ave ul {
  list-style: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  line-height: 1.4em;
}
#StockData .Sourcing .wrapper .reit_ave {
  margin-top: -1.5em;
}

#StockData .pie_chart .apexcharts-canvas {
  /* apexchartsのstyleを上書きし、円グラフが左右中央寄せになるようにする。 */
  margin: auto;
}

#StockData .content {
  font-size: 0.85em;
  padding: 1em;
  line-height: 1.75em;
  display: flex;
  /* width: 460px; */
}
#StockData .content.no_right {
  width: initial !important;
}
#StockData .content .left {
  /* min-width: 300px; */
}
#StockData .content .ownership_form {
  display: flex;
}
#StockData .content .right {
  padding-left: 1em;
}

#StockData .content img {
  max-height: 180px;
  max-width: 220px;
}

#StockData .button_area {
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}
#StockData .button_area button {
  background-color: rgb(25,50,75);
  border: 1px solid rgb(25,50,75);
  border-radius: 3px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  line-height: 2em;
  margin: 0 3px;
  padding: 0 1em;
}
#StockData .button_area select {
  height: 2em;
  padding: 0 0.5em;
  margin-left: 2em;
}

#StockData .balloon_parent {
  position: relative;
  cursor: pointer;
}
#StockData .balloon_parent .balloon {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  font-size: 12px;
  font-weight: normal;
  color: white;
  background-color: gray;
  border-radius: 6px;
  top: calc(100% + 15px);
  z-index: 1;
  width: max-content;
  text-align: left;
}
#StockData .balloon_parent:hover .balloon {
  display: inline;
}
#StockData .balloon_parent .balloon.center {
  left: 50%;
  transform: translateX(-50%);
}
#StockData .balloon_parent .balloon.right {
  right: 1px;
}
#StockData .balloon_parent .balloon.left {
  left: 1px;
}

#StockData .Chart .am_fee_pp {
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  font-weight: normal;
  margin-left: 1.5em;
}

#StockData .Chart .tooltip {
  font-weight: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #StockData .content img {
    max-height: 108px;
    max-width: 132px;
  }

  #StockData .Chart .title {
    font-size: 12px;
  }
  #StockData .Chart .rent_range {
    font-size: 1.2em;
  }

  #StockData .balloon_parent .balloon {
    font-size: 10px;
  }
}

@media screen and ( max-width: 1080px ) {
  #StockData .wrapper {
    padding: 10px;
  }
}
