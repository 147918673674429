#Keyword .searchgroup {
  display: flex;
  margin: 15px 0;
  border: 1px solid rgb(153, 153, 153);
  width: 283px;
}
#Keyword .searchgroup input[name="keyword"] {
  position: relative;
  width: calc(100% - 28px - 1px);
  height: 28px;
  border: none;
  background-color: transparent;
  font-weight: normal;
}
#Keyword .searchgroup input[name="keyword"]:focus {
  outline: none;
}
#Keyword .searchgroup .keyword_serch_button {
  border: none;
  border-left: 1px solid rgb(153, 153, 153);
  cursor: pointer;
  padding: 0;
  margin: 1px;
  right: 0;
  top: 0;
  width: 28px;
}
#Keyword .searchgroup .keyword_serch_button svg {
  width: 18px;
  height: 18px;
  opacity: 1;
  padding-top: 3px;
}
#Keyword .searchgroup .keyword_serch_button svg g {
  fill: rgb(153, 153, 153);
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #Keyword .searchgroup {
    width: 215px;
  }
  #Keyword .searchgroup input[name="keyword"] {
    height: 16px;
  }
  #Keyword .searchgroup .keyword_serch_button svg {
    height: 10px;
  }
}