#Detail {
  background-color: var(--bg-color-estate);
  margin-top: var(--header-height);
  margin-bottom: var(--footer-height);
  padding: 10px 40px;
  height: calc(100vh - var(--header-height) - var(--scroll-bar-height) - var(--footer-height) - 10px);
  overflow-x: auto;
}

#Detail .row {
  display: flex;
}
#Detail .column {
  flex-direction: column;
  padding: 10px;
}
#Detail .column:first-child {
  min-width: 36%;
  padding-right: 10px;
}

#Detail table {
  font-size: 0.95em;
  width: 100%;
}
#Detail table, #Detail tr, #Detail td {
  border-collapse: collapse;
  border: 1px solid #ccc;
  background-color: white;
}
#Detail table tr td:first-child {
  /* 幅を狭くして、nowrap指定で文字と同じ幅に調整させる。 */
  white-space: nowrap;
  width: 1px;
  padding-right: 20px;
}
#Detail table tr td:nth-of-type(2) {
  text-align: center;
}
#Detail table tr td {
  padding: 0 6px;
}
#Detail table tr.image_row {
  height: 284px;
}
#Detail table img {
  max-height: 230px;
  max-width: 90%;
  padding: 3px;
}

#Detail .Chart {
  background-color: white;
  border-radius: 20px;
  margin-top: 10px;
}
#Detail .Chart_NOIY_Occ {
  margin-top: 0 !important;
}
#Detail .Chart_ExpRatio, #Detail .Chart_ExpDetail {
  height: auto !important;
}
#Detail .Chart .title {
  text-align: center;
  font-weight: normal;
}
#Detail .Chart .rent_range {
  text-align: center;
  margin-top: 10px;
  margin-bottom: -20px;
  font-size: 0.8em;
}

#Detail .content {
  font-size: 0.85em;
  padding: 1em;
  line-height: 1.75em;
  display: flex;
  /* width: 460px; */
}
#Detail .content.no_right {
  width: initial !important;
}
#Detail .content .left {
  /* min-width: 300px; */
}
#Detail .content .ownership_form {
  display: flex;
}
#Detail .content .right {
  padding-left: 1em;
}

#Detail .content img {
  max-height: 180px;
  max-width: 220px;
}

#Detail .button_area {
  margin-top: 10px;
}
/*
#Detail .button_area button {
  background-color: var(--ultramarine-color);
  border: 1px solid var(--ultramarine-color);
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.85em;
  line-height: 0.7em;
  padding: 8px 15px;
  vertical-align: middle;
  margin-left: 10px;
  font-weight: normal;
}
*/
#Detail .button_area button {
  background-color: rgb(25,50,75);
  border: 1px solid rgb(25,50,75);
  border-radius: 3px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  line-height: 2em;
  vertical-align: middle;
  margin-left: 10px;
  padding: 0 1em;
}

#Detail .balloon_parent {
  position: relative;
  cursor: pointer;
}
#Detail .balloon_parent .balloon {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  font-size: 0.75em;
  font-weight: normal;
  color: white;
  background-color: gray;
  border-radius: 6px;
  top: calc(100% + 15px);
  z-index: 1;
  width: max-content;
}
#Detail .balloon_parent:hover .balloon {
  display: inline;
}
#Detail .balloon_parent .balloon.center {
  left: 50%;
  transform: translateX(-50%);
}
#Detail .balloon_parent .balloon.right {
  right: 1px;
}


@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #Detail table {
    font-size: 1.15em;
  }
  #Detail .content img {
    max-height: 108px;
    max-width: 132px;
  }

  #Detail .Chart .title {
    font-size: 1.5em;
  }
  #Detail .Chart .rent_range {
    font-size: 1.2em;
  }
}

@media screen and ( max-width: 1080px ) {
  #Detail {
    padding: 10px;
  }
}
