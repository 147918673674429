#Contact {
  padding: 10px;
  width: 50%;
  max-width: 600px;
  margin: auto;
  margin-top: 3em;
  padding-bottom: calc(var(--footer-height) + 3em);
}
#Contact.mobile {
  width: unset;
  max-width: unset;
  margin: unset;
  margin-top: 3em;
  padding: 10px 20px;
}

#Contact .headline {
  display: flex;
  flex-direction: column;
  font-weight: normal;
}

#Contact .wrap_contact_form {
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#Contact .title {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 0em;
  font-size: 1.5em;
}

#Contact .contact_form {
  border-collapse: separate;
  border-spacing: 1.5em;
  white-space: nowrap;
}

#Contact .contact_form th {
  text-align: left;
  word-break: keep-all;
}

#Contact .contact_form input[type="text"], #Contact .contact_form textarea {
  background-color: #F0F3FA;
  border: 1px solid #7F7F7F;
  border-radius: 6px;
  margin-left: 1em;
  width: 100%;
  padding: 0.25em 0.5em;
}
#Contact .contact_form input[type="text"] {
  height: 2.2em;
}
#Contact .contact_form textarea {
  margin-left: 0;
  width: calc(100% + 1em);
}
#Contact .contact_form .inquiry_subject input[type="radio"] {
  margin: 0;
  margin-right: 0.5em;
}
#Contact .contact_form .inquiry_subject td > div {
  display: flex;
  align-items: center;
}

#Contact .inquiry_subject table {
  border-collapse: collapse;
}
#Contact .inquiry_subject table, #Contact .inquiry_subject th, #Contact .inquiry_subject td {
  
}
#Contact .inquiry_subject th, #Contact .inquiry_subject td {
  padding: 0.25em 1em;
}

#Contact .contact_form .comment_h {
  margin-bottom: 0.5em;
}
#Contact .contact_form .comments {
  white-space: pre-wrap;
  word-break: break-word;
}

#Contact .button_area {
  display: flex;
  justify-content: center;
  margin-bottom: 6em;
}

#Contact .button_area button {
  background-color: white;
  padding: 0.5em 1em;
  font-size: 1.0em;
  margin: 0 1em;
  border: 1px solid #7F7F7F;
  min-width: 7em;
  cursor: pointer;
}
#Contact.mobile .button_area button {
  margin: unset;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #Contact {
    font-size: 1.1em;
  }
}

@media screen and ( max-width: 1080px ) {
  #Contact {
  }
}
