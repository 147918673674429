#UserRegistrationDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,0,0,0.5); */

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: var(--dialog-z-index);
}

#UserRegistrationDialog .close_button {
  width: 184px;
  text-align: end;
  cursor: pointer;
}
#UserRegistrationDialog .close_button svg {
  fill: #4b4b4b
}


#UserRegistrationDialog  .register_button {
  cursor: pointer;
  width: 180px;
  padding: 10px 6px;
  background: #19324b;
  color: white;
  text-align: center;
  font-size: 0.9em;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #UserRegistrationDialog .close_button {
    width: 170px;
  }
  
  #UserRegistrationDialog  .register_button {
    width: 160px;
    font-size: 1.1em;
  }
}
