.mlit_info_window {
  /* line-height: 1.5em; */
}

.mlit_info_window .window_header {
  display: flex;
  justify-content: space-between;
}
.mlit_info_window .window_header .land_price_type_hidden {
  font-size: 1.25em;
  visibility: hidden;
}
.mlit_info_window .window_header .close_button {
  cursor: pointer;
  margin-left: 1em;
  z-index: 1;
}
.mlit_info_window .window_header .close_button polygon, .close_button path {
  fill: rgb(75, 75, 75);
}
.mlit_info_window .window_header .checkbox {
  margin-top: -2px;
}

.mlit_info_window .land_price_type {
  text-decoration-line: underline;
}

.mlit_info_window .content {
  display: flex;
  /* font-size: 0.85em; */
}

.mlit_info_window .content_OfficialLandPrices {
  font-size: 1em;
  margin-top: -2em;
}
.mlit_info_window .content_OfficialLandPrices .content {
  display: flex;
  flex-direction: column;
}
.mlit_info_window .content_OfficialLandPrices .content:nth-of-type(n+2) {
  margin-top: 2em;
}

.mlit_info_window .content_OfficialLandPrices .target_year_name_ja {
  text-align: right;
}
.mlit_info_window .content_OfficialLandPrices .u_current_years_price_ja {
  text-align: right;
  margin-left: 2em;
}
.mlit_info_window .content_OfficialLandPrices .show_details {
  text-align: right;
  margin-top: 1em;
}
.mlit_info_window .content_OfficialLandPrices .show_details a {
  cursor: pointer;
  text-decoration-line: underline;
  color: blue;
}

.mlit_info_window .window_header .zoning_title {
  font-size: 1.125em;
  margin-bottom: 5px;
}
.mlit_info_window .content_zoning {
  font-size: 1em;
}

.point_icon_text {
  position: absolute;
  transform: translateY(-50%);
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.point_icon_text .text {
  display: flex;
  flex-direction: column;
}
.point_icon_text .text > span {
  line-height: 1em;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
}
