.building_type_box {
  display: flex;
  font-size: 0.85em;
  font-weight: bold;
  width: 283px;
}
.col {
  width: 50%;
  line-height: 1.75em;
}
.building_type_common {
  color: black;
  margin: 0.2em 0.2em;
  display: flex;
  align-items: center;
}
.building_type_common input, .building_type_common label {
  /* 親要素のdivのクリックイベントだけが動作するようにする。inputとlabelのクリックで親要素で定義したonCLickイベントが動作しないようにする。 */
  pointer-events: none;
}
.building_type1 {
  background-color: rgb(248, 203, 173);
}
.building_type2 {
  background-color: rgb(255, 230, 153);
}
.building_type3 {
  background-color: rgb(255, 255, 153);
}
.building_type4 {
  background-color: rgb(198, 224, 180);
}
.building_type5 {
  background-color: rgb(189, 215, 238);
}
.building_type6 {
  background-color: rgb(219, 219, 219);
}
.building_type99 {
  background-color: rgb(219, 219, 219);
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  .building_type_box {
    width: 215px;
  }  
}