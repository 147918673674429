#List {
  height: calc(100vh - var(--header-height) - var(--scroll-bar-height) - var(--footer-height));
  padding: 10px;
  padding-left: 40px;
  font-size: 0.85em;
  overflow-x: auto;
}

#List .twrapper {
  overflow-x: scroll;
  white-space: nowrap;
  height: calc(100vh - var(--header-height) - var(--footer-height) - var(--scroll-bar-height) - 32px);  /* ボタンエリア(32px) */
  position: relative;
}
#List .twrapper table .fixcell {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

#List table {
  border-collapse: collapse;
  width: auto;
}

/* 文言説明の吹き出しが最前面に表示されるようにするための設定(ここから) */
#List table thead {
  position: relative;
  z-index: 2;
}
#List table tbody {
  position: relative;
  z-index: 1;
}
/* 文言説明の吹き出しが最前面に表示されるようにするための設定(ここまで) */

#List table th, #List table td {
  padding: 5px 15px;
}

#List table th {
  position: relative;
  background: var(--thead-bg-color);
  user-select: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
}

#List table th svg {
  padding-top: 3px;
}

#List table thead tr th > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#List table th:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1px;
  height: 100%;
  background: white;
}

#List table td {
  background: #eee;
  z-index: 3;
  position: relative;
}
#List table td.has_not_hist_data {
  background: white !important;
  border: none !important;
}
#List table td.right {
  text-align: right;
}
#List table td.center {
  text-align: center;
}

#List table tr:nth-child(odd) td {
	background: #fff;
}

#List table th.checkbox {
  z-index: 2 !important;
}
#List table th.checkbox, #List table td.checkbox {
  max-width: 10px!important;
  min-width: 10px!important;
  width: 10px!important;
  left: 0px;
  padding: 5px 11px 5px 10px;
  background-color: white !important;
}
#List table td.checkbox {
  z-index: 1 !important;
}
#List table td.checkbox input[type="checkbox"] {
  margin: 0;
  vertical-align: middle;
}

#List table thead tr:nth-child(1) th:first-child:before {
  border-bottom: 1px solid white !important;
  background: white;  /* 縦スクロール時に、チェックボックスの見出しの上側の隙間に文字が微かに見えてしまうのに対応。 */
}

#List table th.data_pdf_button {
  z-index: 2 !important;
}
#List table th.data_pdf_button, #List table td.data_pdf_button {
  width: 90px !important;
  min-width: 90px !important;
  max-width: 90px !important;
  padding: 5px 5px;
  text-align: center;
  left: 32px;  /* checkboxの幅(10px)＋padding(21px)＋border(1px) */
}
#List table td.data_pdf_button {
  z-index: 1 !important;
}
#List table td.data_pdf_button button {
  background-color: #c45e4a;
  border: 1px solid #c45e4a;
  border-radius: 3px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  line-height: 1.5em;
  margin: 0 3px;
}
#List table td.data_pdf_button button.nippon_building {
  background-color: #80350E;
  border: 1px solid #80350E;
}
#List table td.data_pdf_button .button_area {
  display: flex;
  justify-content: space-between;
}
#List table td.data_pdf_button button.top_margin {
  margin-top: 3px;
}

#List table th.building_type {
  z-index: 2 !important;
}
#List table th.building_type, #List table td.building_type {
  left: 0px;
  white-space: nowrap;
}
#List table td.building_type {
  z-index: 1 !important;
}

#List table th.cap_rate {
  z-index: 2 !important;
}
#List table th.cap_rate, #List table td.cap_rate {
  width: 70px !important;
  max-width: 70px !important;
  min-width: 70px !important;
  left: 0;
  text-align: center;
}
#List table td.cap_rate {
  z-index: 1 !important;
}

#List table td.cap_rate.not_disclosed_ja {
  /* padding: 0; */
  text-align: center;
}
#List table td.cap_rate.not_disclosed_en {
  /* padding: 0; */
  text-align: center;
  font-size: 0.9em;
}
#List table th.property_name {
  z-index: 6 !important;
}
#List table th.property_name, #List table td.property_name {
  min-width: 220px;
  max-width: 220px;
  white-space: normal;
  left: 0px;
}
#List table td.property_name {
  z-index: 5 !important;
}
#List table th.transaction_price, #List table td.transaction_price {
  /* min-width: 120px; */
}

#List table th.investment_corp, #List table td.investment_corp {
  /* min-width: 220px;
  max-width: 220px;
  white-space: normal; */
}

#List table th.blank, #List table td.blank {
  background: white !important;
  border: none !important;
  width: 2em;
}
#List table th.blank:before {
  border: none !important;
}

#List table th.update_date {
  background: white !important;
  border: none !important;
}
#List table th.update_date:before {
  border: none !important;
}
#List table td.update_date {
  background: white !important;
  border: none !important;
}
#List table th.update_date, #List table td.update_date {
  padding: 5px 10px 5px 20px !important;
}

#List table td.cr_noi {
  text-align: center;
}

#List table td.na {
  text-align: center;
}

#List .sortable {
  cursor: pointer;
}

#List .indicator_neutral {
  fill: #aaaaaa;
  fill-opacity: 1;
}
#List .indicator_asc, #List .indicator_dsc {
  fill: #000;
  fill-opacity: 1;
}

#List .order_by {
  background: #c0f8ff !important;
}

#List .more_button {
  background-color: #fff;
  border: none;
  font-size: 1em;
  height: 1.8em;
  letter-spacing: .1em;
  margin: 10px 0;
  cursor: pointer;
  left: 0;
  position: sticky;
}


#List .labeledEstateData {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  padding-bottom: 10px;
  white-space: nowrap;
  overflow-x: auto;
}
#List .labeledEstateData ul {
  list-style: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
#List .labeledEstateData ul li {
  margin-top: 4px;
  display: flex;
  line-height: 1.75em;
  margin-right: 6px;
  font-size: 0.9em;
}
#List .labeledEstateData ul li .wrap {
  border: solid 1px rgb(65, 113, 156);
  border-radius: 3px;
  background-color: rgb(208, 206, 206);
  padding: 0px 20px 0px 5px;
  display: flex;
}
#List .labeledEstateData ul li:last-child {
  margin-right: 0px;
}
#List .labeledEstateData ul li .checkbox {
  margin-right: 3px;
}

.width_adjust {
  width: fit-content;
}


#List .balloon_parent {
  /* position: relative; */
}
#List .balloon_parent .balloon {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  font-size: 0.9em;
  font-weight: normal;
  color: white;
  background-color: gray;
  border-radius: 6px;
  top: calc(100% + 15px);
}
#List .balloon_parent:hover .balloon {
  display: inline;
}
#List .balloon_parent .balloon.center {
  left: 50%;
  transform: translateX(-50%);
}
#List .balloon_parent .balloon.right {
  right: 1px;
}

#List .top_button_area {
  height: 32px;
  margin-left: 30px;
}

#List .top_button_area button {
  background-color: rgb(25,50,75);
  border: 1px solid rgb(25,50,75);
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  line-height: 2em;
  margin: 0 3px;
  padding: 0 1em;
}

#List .popup_parent {
  position: relative;
}
#List .popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px grey;
  border-radius: 3px;
  padding: 0 6px;
  z-index: 9999;
  display: flex;
  align-items: center;
  /* ポップアップがマウスイベントを拾わないようにする。*/
  pointer-events: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #List {
    /* height: calc(100vh - 27px - 20px); */
    /* margin-top: 27px; */
  }  
  #List .twrapper {
    /* height: calc(100vh - 27px - 20px); */
  }
  #List .top_button_area {
    height: 26px;
  }
  #List table th, #List table td {
    padding: 3px 15px;
  }
  #List table th.data_pdf_button, #List table td.data_pdf_button {
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
  }
  #List table th.building_type, #List table td.building_type {
    width: 72px !important;
    max-width: 72px !important;
    min-width: 72px !important;
    left: 0px;
  }
  #List table th.cap_rate, #List table td.cap_rate {
    width: 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    left: 102px;
  }
  #List table th.property_name, #List table td.property_name {
    min-width: 200px;
    max-width: 200px;
    white-space: normal;
  }
}

@media screen and ( max-width: 1080px ) {
  #List {
    padding-left: 10px;
  }
}

