#InfoMenu .menu-container {
  display: flex;
}

#InfoMenu .menu-button {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 6px 6px;
  cursor: pointer;
  margin: 0;
  white-space: nowrap;
  border-left: none;
}

#InfoMenu .menu-button:first-child {
  border-left: 1px solid black;
}

#InfoMenu .menu-button.active {
  background-color: #d9d9d9;
}

#InfoMenu .dropdown {
  position: relative;
}

#InfoMenu .dropdown .menu-button {
  border-left: none;
  width: 130px;
}


#InfoMenu .dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%; /* 下に表示 */
  left: 0;
  background-color: white;
  z-index: 1;
  margin-left: -1px;
  width: calc(100% + 1px);
}

#InfoMenu .submenu-button {
  padding: 6px 6px;
  background-color: white;
  color: black;
  border: 1px solid black;
  border-top: none;
  cursor: pointer;
  width: 100%;
  white-space: nowrap;
  font-size: 0.75em;
}

#InfoMenu .submenu-button.active {
  background-color: #d9d9d9;
}


@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #InfoMenu .menu-container {
    font-size: 1.25em;
  }  
  #InfoMenu .dropdown .menu-button {
    width: 100px;
  }
}

