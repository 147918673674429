#Header {
  width: 100%;
  display: flex;
  align-items: center;
}
#Header {
  height: var(--header-height);
  background-color: rgb(25, 50, 75);
  position: fixed;
  top: 0;
  z-index: var(--header-z-index);
}

#Header .title {
  margin-left: 5px;
  font-size: 1.2em;
  font-weight: bold;

  color: white;
  background-color: rgb(25, 50, 75);
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  line-height: 0.7em;
  padding: 0.5em 0.5em;
  margin-right: 5px;
  vertical-align: middle;
  border-radius: 6px;
  transition: background-color 0.5s;
}
#Header .title .dot {
  color: #ED7D31;
  font-size: 1.4em;
}

#Header .display_mode {
  background-color: rgb(68, 84, 106);
  color: white;
  margin-left: 14px;
  padding-left: 6px;
  height: 31px;
  width: 102px;
  border: none;
  font-size: 0.85em;
  font-weight: bold;
}
#Header .display_mode:hover {
  cursor: pointer;
}
#Header .display_mode option {
  font-size: 1em;
  font-weight: bold;
  background-color: white;
  color: black;
}

#Header .right {
  position: absolute;
  right: 10px;
  height: 100%;
}

#Header .right button {
  /*background-color: rgb(25, 50, 75);
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: .9em;
  height: 100%;
  outline: none;*/
  
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  height: 100%;
  font-size: 0.9em;
  vertical-align: -1.0em;
}
#Header .right button:last-child {
  margin-right: 0px;
  margin-left: 10px;
}

#Header select {
  /* font-size: 16px; */
  color: #333;
  /* width: 200px; */
  height: calc(100% - 1px);
  border: none;
  padding: 10px;
  background: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
#Header select:focus {
  outline: none;
}

#Header .menu-icon {
  height: 40%;
  vertical-align: middle;
  margin-left: 12px;
}

#Header .menu-icon.value {
  margin-right: 0px;
  margin-top: -2px;
}

#Header .dropdown {
  position: relative;
  display: inline-block;
  z-index: var(--header-z-index);
  line-height: .7em;
  height: 100%;
}

#Header .dropdown-button {
/*  background-color: rgb(25, 50, 75);*/
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  height: 80%;
  font-size: 0.9em;
  vertical-align: -1.3em;
}

#Header .mail-icon {
  height: 32%;
}

/* .dropdown-button:hover {
  background-color: #2980b9;
} */

#Header .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 5px;
  font-size: 0.85em;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  word-break: keep-all;
}

#Header .dropdown-content a {
  color: black;
  padding: 9px 16px;
  text-decoration: none;
  display: block;
}

#Header .dropdown-content a:hover {
  background-color: #ddd;
}

#Header .dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #Header .display_mode {
    height: 21px;
  }

  #Header .title {
    font-size: 1.4em;
  }  
  #Header .title .dot {
    font-size: 1.6em;
  }

  #Header .dropdown-button {
    font-size: 1.1em;
  }

  #Header .mail-icon {
    vertical-align:  -.3em;
  }  
}
