
#Main {
  display: flex;
}
#Main.Data {
  background-color: var(--bg-color-estate);
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
}
