#SellOrBuyType {
  width: 128px;
}
#SellOrBuyType.en {
  width: 128px;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #SellOrBuyType {
    width: 99px;
  }
  #SellOrBuyType.en {
    width: 99px;
  }
}
