#DialogValueMap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--dialog-z-index);
}

#DialogValueMap .content {
  z-index: 2;
  min-width: 300px;
  max-width: 50%;
  padding: 1em 1.5em 1.5em 1.5em;
  background: #fff;
  border-radius: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#DialogValueMap .content p {
  font-weight: normal;
}

#DialogValueMap .content button {
  background-color: #F2F2F2;
  border: none;
  border-radius: 30px;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-size: 0.85em;
  line-height: 1;
  padding: 0.5rem 0;
  vertical-align: middle;
  width: 100px;
  margin: 1em 2em 0 2em;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #DialogValueMap .content p {
    font-size: 1.2em;
  }  
}
