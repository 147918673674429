#RelatedPartyTxType {
  width: 128px;
}
#RelatedPartyTxType.en {
  width: 128px;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #RelatedPartyTxType {
    width: 99px;
  }
  #RelatedPartyTxType.en {
    width: 99px;
  }
}
