#Dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--dialog-z-index);
}

#Dialog .content {
  z-index: 2;
  min-width: 300px;
  max-width: 50%;
  padding: 1em;
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#Dialog .content button {
  background-color: #c45e4a;
  border: 1px solid #c45e4a;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.85em;
  line-height: 1;
  padding: 0.5rem 0;
  vertical-align: middle;
  width: 100px;
  margin-top: 1em;
}