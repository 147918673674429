#Addr .searchgroup, #Addr_chart .searchgroup {
  display: flex;
}

.Addr1 {
  display: flex;
  flex-direction: column;
  width: 128px;
}

.Addr2 {
  display: flex;
  flex-direction: column;
  width: 128px;
  margin-left: 26px;
}

/* .Addr2 select {
  max-width: 100px;
} */

/* 分散チャートの都道府県／市区検索 */
#Addr_chart select {
  background-color: var(--bg-color-estate);
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  .Addr1, .Addr2 {
    width: 99px;
  }
  .Addr2 {
    margin-left: 16px;
  }
}
