#HamburgerMenu .menu-toggle {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 20px;
}

#HamburgerMenu .menu-toggle .bar {
  height: 2px;
  width: 20px;
  background: white;
  margin: 3px 0;
}

#HamburgerMenu .nav-menu {
  position: fixed;
  top: 0;
  left: -230px; /* start off the screen */
  width: 180px;
  height: calc(100% - var(--header-height) - var(--footer-height) - 40px);  /* 40pxはpadding分 */
  margin-top: var(--header-height);
  background-color: #E7E6E6;
  transition: 0.3s;
  overflow-y: auto; /* Scrollable if the content is longer than screen height */
  padding: 20px;
  box-shadow: 2px 0px 5px rgba(0,0,0,0.3);
  z-index: var(--nav-menu-z-index);
}

#HamburgerMenu .nav-menu.active {
  left: 0; /* Slide in */
}

#HamburgerMenu .nav-menu ul {
  list-style-type: none;
  padding: 0;
}

#HamburgerMenu .nav-menu li {
  display: flex;
  align-items: center;
  margin-top: 1.6em;
}

#HamburgerMenu .nav-menu a {
  text-decoration: none;
  color: black;
  margin-left: 20px;
  cursor: pointer;
}
#HamburgerMenu .nav-menu a.contact {
  margin-left: 0;
}

#HamburgerMenu .nav-menu img {
  width: 16px;
}

#HamburgerMenu .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  background: none;
  border: none;
  outline: none;
  color: black;
}
#HamburgerMenu .close-button.mobile {
  top: 0px;
  right: 6px;
  padding: 0;
}

#HamburgerMenu .dropdown-content {
  left: 0;
  transform: unset;
  word-break: keep-all;
  margin-top: 5px;
}

#HamburgerMenu .dropdown-content a {
  margin-left: 0;
}

#HamburgerMenu .mail-icon, #HamburgerMenu .value-icon {
  filter: brightness(0);
}
#HamburgerMenu .mail-icon {
  width: 18px !important;
  margin-left: -1px;
}
#HamburgerMenu .value-icon {
  width: 12px !important;
  margin-left: 2px;
  margin-right: 2px;
}

#HamburgerMenu .dropdown-button {
  color: black;
  vertical-align: unset;
}
#HamburgerMenu .dropdown-value {
  margin-left: 20px;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #HamburgerMenu .menu-toggle .bar {
    width: 15px;
    margin: 2px 0;
  }
  #HamburgerMenu .nav-menu a {
    font-size: 1.2em;
  }
  #HamburgerMenu .nav-menu span {
    font-size: 1.2em;
  }
  #HamburgerMenu .nav-menu {
    left: -200px; /* start off the screen */
    width: 150px;
  }  
}
