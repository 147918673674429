#Guide {
  font-weight: normal;
  padding: 20px 10px;
  width: 50%;
  margin: auto;
  min-width: 1000px;
}

#Guide h1 {
  text-align: center;
}

#Guide section {
  margin-top: 4em;
}

#Guide .list {
  border-top: 1px solid #e5e5e5;
}

#Guide .list-item {
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  padding: 0.75em 0;
}

#Guide .list-item .title {
  width: 28em;
}
#Guide .list-item .description {
  width: 80em;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
}

@media screen and ( max-width: 1000px ) {
  #Guide {
    min-width: calc(100% - 20px);
  }
}

@media screen and ( max-width: 1080px ) {
  #Guide {
    padding: 10px;
  }
}
