/* GoogleMapのスタイルを無効化する。 */
.gm-style {
  font: inherit;
  font-family: var(--my-font-family);
}

.gm-style .gm-style-iw {
  font-weight: inherit;
  font-size: inherit;
  outline: none;  /* 吹き出しの枠線が表示されないようにする。 */
}
.gm-style iframe + div {
  /* https://stackoverflow.com/questions/66669173/google-maps-js-api-shows-blue-focus-border */
  border: none !important;
}

#Map {
  position: relative;
}

#Map .labeledEstateData {
  position: absolute;
  top: 9px;
  right: 10px;
}

#Map .labeledEstateData ul {
  list-style: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

#Map .labeledEstateData ul li {
  /* border: solid 1px rgb(65, 113, 156); */
  border: solid 1px rgb(25, 50, 75);
  border-radius: 3px;
  background-color: rgb(208, 206, 206);
  margin-top: 4px;
  font-size: 0.8em;
  display: flex;
  line-height: 1.75em;
  padding: 0px 20px 0px 5px;
  align-items: center;
}
#Map .labeledEstateData ul li:first-child {
  margin-top: 0px;
}

#Map .labeledEstateData ul li .checkbox {
  margin-right: 3px;
}

#Map .onlyLabeledButtonArea {
  position: absolute;
  right: 12px;
  top: 9px;
}
#Map .onlyLabeledButtonArea input[type="checkbox"] {
  display: none;
}
#Map .onlyLabeledButtonArea input[type="checkbox"] + label {
  cursor: pointer;
  /* アイコン画像と同じ高さにする(これをしないと画像の高さを超えた領域もクリック対象になってしまう)。 */
  display: block;
  height: 32px;
}
#Map .onlyLabeledButtonArea input[type="checkbox"] + label > img {
  height: 32px;
}

#Map .top_button_area {
  position: absolute;
  left: 12px;
  top: 9px;
}
#Map .top_button_area .row {
  margin-bottom: 1em;
}

#Map .top_button_area .estate button {
  background-color: rgb(25,50,75);
  border: 1px solid rgb(25,50,75);
  border-radius: 3px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  line-height: 2em;
  margin: 0 3px;
  padding: 0 1em;
}

#Map .map-container {
  position: relative;
}

#Map .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 地図を暗くする */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#Map .spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #Map .top_button_area {
    height: 26px;
  }  
}
