#ValueList {
  height: calc(100vh - var(--header-height) - var(--scroll-bar-height) - var(--footer-height));
  padding: 10px;
  padding-left: 40px;
  font-size: 0.85em;
  overflow-x: auto;
}

#ValueList .evaluation_date {
  height: 2em;
}


#ValueList .balloon_parent {
  /* position: relative; */
}
#ValueList .balloon_parent .balloon {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  font-size: 0.9em;
  font-weight: normal;
  color: white;
  background-color: gray;
  border-radius: 6px;
  top: calc(100% + 15px);
  z-index:999999;
}
#ValueList .balloon_parent:hover .balloon {
  display: inline;
}
#ValueList .balloon_parent .balloon.center {
  left: 50%;
  transform: translateX(-50%);
}
#ValueList .balloon_parent .balloon.right {
  right: 1px;
}

#ValueList .minus {
  color: red;
}

#ValueList table td a {
  color: inherit;  /* リンクの色を元の色に設定 */
  text-decoration: none;  /* リンクの下線を削除 */
}

#ValueList .balloon {
  position: absolute;
  display: none;
  padding: 0.25em 0.5em;
  font-size: 0.9em;
  font-weight: normal;
  color: white;
  background-color: gray;
  border-radius: 6px;
  top: calc(100% + 15px);
  text-align: left;
  z-index:999999;
}
#ValueList table th:hover .balloon {
  display: inline;
}
#ValueList table th .balloon.center {
  left: 50%;
  transform: translateX(-50%);
}
#ValueList table th .balloon.left {
  left: 1px;
}
#ValueList table th .balloon.right {
  right: 1px;
}
#ValueList table th .balloon.nowrap {
  white-space: nowrap;
}
#ValueList table th .balloon.wrap {
  white-space: break-spaces;
  width: 500px;
}




#ValueList .twrapper {
  overflow-x: scroll;
  white-space: nowrap;
  height: calc(100vh - var(--header-height) - var(--footer-height) - var(--scroll-bar-height) - 2em);  /* 評価日(2em) */
  position: relative;
}
#ValueList .twrapper table .fixcell {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

#ValueList table {
  border-collapse: collapse;
  width: auto;
}

/* 文言説明の吹き出しが最前面に表示されるようにするための設定(ここから) */
#ValueList table thead {
  position: relative;
  z-index: 2;
}
#ValueList table tbody {
  position: relative;
  z-index: 1;
}
/* 文言説明の吹き出しが最前面に表示されるようにするための設定(ここまで) */

#ValueList table th, #ValueList table td {
  padding: 5px 15px;
}

/* #ValueList table tr:nth-child(odd) th, #ValueList table tr:nth-child(odd) td {
  border: 1px solid white;
}
#ValueList table tr:nth-child(even) th, #ValueList table tr:nth-child(even) td {
  border: 1px solid #eee;
} */

#ValueList table th {
  position: relative;
  background-color: var(--thead-bg-color);
  user-select: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
  vertical-align: top;
  border-top: none !important;  /* 縦スクロールした際に、文字が重ならないように上部の罫線は引かない。 */
}

#ValueList table thead tr th > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#ValueList table thead tr:nth-child(1) th {
  /* 1段目 */
  height: 20px;
}

#ValueList table thead tr:nth-child(2) th {
  /* 2段目 */
  top: 32px;
  vertical-align: top;
}
#ValueList table thead th:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1px;
  height: 100%;
  background: white;
}
#ValueList table thead .second:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: white;
  z-index: -1;
}
#ValueList table thead .left_border {
  content: "";
  position: absolute;
  left: 0;
  width: 1px;
  height: 100%;
  background: white;
  z-index: -1;
}

#ValueList table th.gfa {
  padding: 0 6px !important;
}

/* #ValueList table thead tr:nth-child(1) th:before {
  border-bottom: 1px solid var(--thead-bg-color) !important;
}
#ValueList table thead tr:nth-child(2) th:before {
  border-top: 1px solid var(--thead-bg-color) !important;
}
#ValueList table thead tr:nth-child(2) th.order_by:before {
  border-top: 1px solid #c0f8ff !important;
} */
/* #ValueList table thead tr:nth-child(1) th:first-child:before {
  border-bottom: 1px solid white !important;
  background: white;
}
#ValueList table thead tr:nth-child(2) th:first-child:before {
  border-top: 1px solid white !important;
} */
/* #ValueList table thead tr:nth-child(1) th.has_second_heading:before {
  border-left: 3px solid var(--thead-bg-color) !important;
}
#ValueList table thead tr:nth-child(2) th.has_second_heading:before {
  border-top: 1px solid white !important;
} */

#ValueList table thead tr th.has_second_heading {
  vertical-align: bottom;
  padding: 0;
}
#ValueList table thead tr th.no_left_border:before {
  content: unset;
}
/* #ValueList table thead tr th.has_second_heading > div {
  border-left: 3px solid white;
  border-top: 1px solid white;
} */

#ValueList table td {
  background: #eee;
  z-index: 1;
  position: relative;
}
#ValueList table td.has_not_hist_data {
  background: var(--bg-color) !important;
  border: none !important;
}
#ValueList table td.center {
  text-align: center;
}
#ValueList table td.right {
  text-align: right;
}

#ValueList table tr:nth-child(odd) td {
	background: #fff;
}

#ValueList table th.property_name, #ValueList table td.property_name {
  min-width: 220px;
  max-width: 220px;
  white-space: normal;
  left: 0px;
}
#ValueList table th.property_name {
  z-index: 6 !important;
}
#ValueList table td.property_name {
  z-index: 5 !important;
}

#ValueList table th.building_type {
  z-index: 2 !important;
}
#ValueList table th.building_type, #ValueList table td.building_type {
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  left: 0px;
  white-space: break-spaces;
}
#ValueList table td.building_type {
  z-index: 1 !important;
}

#ValueList table td.data_ir button {
  background-color: #c45e4a;
  border: 1px solid #c45e4a;
  border-radius: 3px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  line-height: 1.5em;
  margin: 0 3px;
}
#ValueList table td.data_ir .button_area {
  display: flex;
  justify-content: space-between;
}
#ValueList table td.data_ir button.top_margin {
  margin-top: 3px;
}

#ValueList table td.portfolio button {
  background-color: var(--ultramarine-color);
  border: 1px solid var(--ultramarine-color);
  border-radius: 3px;
  color: white;
  font-weight: normal;
  cursor: pointer;
  line-height: 1.5em;
  margin: 0 3px;
}
#ValueList table td.portfolio .button_area {
  display: flex;
  justify-content: space-between;
}
#ValueList table td.portfolio button.top_margin {
  margin-top: 3px;
}

#ValueList table th.stock_name {
  z-index: 6 !important;
}
#ValueList table th.stock_name, #ValueList table td.stock_name {
  min-width: 220px;
  max-width: 220px;
  white-space: normal;
  left: -1px;  /* 横スクロールした際に、罫線部分に文字が重ならないように。 */
}
#ValueList table td.stock_name {
  z-index: 5 !important;
}

#ValueList table th.blank, #ValueList table td.blank {
  background: var(--bg-color) !important;
  border: none !important;
  width: 2em;
}
#ValueList table th.blank:before {
  border: none !important;
}

#ValueList table td.cr_noi {
  text-align: center;
}

#ValueList table td.na {
  text-align: center;
}

#ValueList table td.cap_rate {
  padding-right: 2em;
}
#ValueList table td.gfa {
  padding-right: 2em;
}

#ValueList .sortable {
  cursor: pointer;
}

#ValueList table th svg {
  padding-top: 3px;
}

#ValueList .indicator {
  height: 20px;
}
#ValueList .indicator_neutral {
  fill: #aaaaaa;
  fill-opacity: 1;
}
#ValueList .indicator_asc, #ValueList .indicator_dsc {
  fill: #000;
  fill-opacity: 1;
}
#ValueList .indicator_orderBy_sector {
  fill: #000;
  fill-opacity: 1;
}

#ValueList .order_by {
  background: #c0f8ff !important;
}

#ValueList .more_button {
  background-color: #fff;
  border: none;
  font-size: 1em;
  height: 1.8em;
  letter-spacing: .1em;
  margin: 10px 0;
  cursor: pointer;
  left: 0;
  position: sticky;
}

.width_adjust {
  width: fit-content;
}


#ValueList .popup_parent {
  position: relative;
}
#ValueList .popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px grey;
  border-radius: 3px;
  padding: 6px;
  z-index: 999999;
  /* ポップアップがマウスイベントを拾わないようにする。*/
  pointer-events: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #ValueList table th, #ValueList table td {
    padding: 5px 10px;
  }
  #ValueList table thead tr th.has_second_heading {
    padding: 0;
  }
}

@media screen and ( max-width: 1080px ) {
  #ValueList {
    padding-left: 10px;
  }
}
