#LoginDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

#LoginDialog .content {
  z-index: 2;
  min-width: 350px;
  padding: 1em;
  margin-top: 5%;
  background: #fff;
  font-weight: normal;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#LoginDialog .content label {
  width: 90%;
  margin-top: 0.5em;
}
#LoginDialog .content label > div {
  font-size: 0.85em;
}

#LoginDialog .content input {
  font-weight: normal;
  width: 92%;
  border: none;
  border-radius: 6px;
  padding: 0.25em 1em;
  background-color: #f2f2f2;
}

#LoginDialog .content .errorMessage {
  margin-top: 1em;
  font-size: 0.8em;
  color: red;
}

#LoginDialog .content .buttonArea {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

#LoginDialog .content .buttonArea button {
  background-color: #ED7D31;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  font-size: 0.85em;
  line-height: 1;
  padding: 0.5rem 0;
  vertical-align: middle;
  width: 49%;
  margin-top: 1em;
}