:root {
  --my-font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  --bg-color: white;
  --bg-color-estate: #f2f2f2;
  /* 表の見出しの背景色 */
  --thead-bg-color: rgb(221, 235, 247);

  --ultramarine-color: rgb(68, 84, 106);
  --orange-color: rgb(237, 125, 49);

  --header-z-index: 9999;
  --footer-z-index: 9999;
  --dialog-z-index: 10000;
  --nav-menu-z-index: 10001;

  /* ヘッダーとフッターの高さ */
  /* Map.tsxでヘッダーとフッターの高さを考慮した計算をしている。この値を変更する際は、Map.tsxも変更すること。*/
  --header-height: 40px;
  --footer-height: 40px;

  --scroll-bar-height: 10px;

  --calendar-border-color: #ccc;

  --value-data-border-color: #bfbfbf;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  /* font-family: Calibri, "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif !important; */
  font-family: var(--my-font-family);
  font-weight: bold;
  letter-spacing: 0.025em;
  background-color: var(--bg-color);
}

button, input, select, textarea, option {
  /* font-family : inherit; */
  font-family: var(--my-font-family);
  font-weight: bold;
  font-size   : 0.85em;
}

.searchgroup {
  margin-bottom: 0.5em;
  font-weight: normal;
}

.searchgroup .searchfieldlabel {
  display: block;
  font-size: 0.85em;
}

.searchgroup select {
  height: 2em;
  border-radius: 6px;
  border: none;
}
.searchgroup select, .searchgroup option, .searchgroup button {
  font-weight: normal;
}

.hidden {
  visibility: hidden;
}

.display_none {
  display: none !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  :root {
    --header-height: 32px;
    --footer-height: 26px;  
  }
  body {
    font-size: 10px;
  }
  .searchgroup select {
    height: 2.4em;
  }
}
