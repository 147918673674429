#ValueData {
  margin-top: var(--header-height);
  margin-bottom: var(--footer-height);
  padding: 10px 40px;
  font-weight: normal;
}

#ValueData .evaluation_date {
  text-align: right;
  padding-right: 10px;
  font-size: 0.85em;
  font-weight: bold;
}

#ValueData .row {
  display: flex;
}
#ValueData .column {
  flex-direction: column;
  padding: 10px;
}
#ValueData .column:first-child {
  min-width: 36%;
  padding-right: 30px;
}

#ValueData table {
  font-size: 0.95em;
  width: 100%;
}
#ValueData table, #ValueData tr, #ValueData td {
  border-collapse: collapse;
  background-color: white;
}
#ValueData tr, #ValueData td {
  border: 1px solid var(--value-data-border-color);
}
#ValueData tr {
  border-left: 2px solid var(--value-data-border-color);
  border-right: 2px solid var(--value-data-border-color);
}
#ValueData tr:first-child,
#ValueData tr.first {
  border-top: 2px solid var(--value-data-border-color);
}
#ValueData tr:last-child,
#ValueData tr.last {
  border-bottom: 2px solid var(--value-data-border-color);
}

#ValueData table tr td:first-child {
  white-space: nowrap;
}
#ValueData table.basic tr td:first-child {
  width: 40%;
}
#ValueData table.detail tr td:first-child {
  width: 30%;
}
#ValueData table tr td:nth-of-type(2) {
  text-align: center;
}
#ValueData table tr td {
  padding: 3px 6px;
}
#ValueData table tr.image_row {
  height: 284px;
}
#ValueData table img {
  max-height: 260px;
  max-width: 90%;
  padding: 3px;
}

#ValueData table tr.blank_row {
  height: 2em;
}
#ValueData table tr.blank_row, #ValueData table tr.blank_row td {
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
}

#ValueData table tr.detail_1 td:nth-child(1),
#ValueData table tr.detail_1 td:nth-child(2) {
  text-align: center;
  font-weight: bold;
}

#ValueData table tr.detail_2_title {
  height: 4em !important;
  vertical-align: bottom;
}

#ValueData table.detail tr td:nth-child(2) {
  white-space: nowrap;
  font-size: 0.85em;
  width: 20%;
}

#ValueData table.detail tr td:nth-child(3) {
  font-size: 0.75em;
}


#ValueData table tr.detail_2 td:nth-child(2), #ValueData table tr.detail_3 td:nth-child(2) {
  text-align: right;
}

#ValueData table tr.detail_3 td:nth-child(1),
#ValueData table tr.detail_3 td:nth-child(2) {
  text-align: center;
  font-weight: bold;
}

#ValueData td.gray_cell {
  background-color: #BFBFBF;
}

#ValueData .bold {
  font-weight: bold !important;
}
#ValueData .normal {
  font-weight: normal !important;
}
#ValueData tr.bold {
  border: 2px solid var(--value-data-border-color);
  font-weight: bold !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #ValueData table {
    font-size: 1.15em;
  }
}

@media screen and ( max-width: 1080px ) {
  #ValueData {
    padding: 10px;
  }
}
