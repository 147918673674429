#Footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: var(--footer-height);
  background-color: rgb(25, 50, 75);
  flex-shrink: 0;
  color: white;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: var(--footer-z-index);
}

#Footer .disclaimer {
  font-weight: normal;
  font-size: 0.8em;
  padding-left: 8px;
}

#Footer button {
  background-color: rgb(25, 50, 75);
  color: white;
  height: 21px;
  border: none;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: .85em;
  font-weight: 400;
  line-height: .7em;
  margin-right: 5px;
  padding: 0.5em;
  transition: background-color .5s;
  vertical-align: middle;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #Footer {
    height: var(--footer-height);
  }
  #Footer button {
    height: 15px;
  }
}

#Footer .menu-icon {
  height: 40%;
  vertical-align: middle;
  margin-left: 12px;
}


#Footer .mail-icon {
  height: 100%;
  vertical-align: middle;
  margin-left: 6px;
}

