.search-panel {
  width: 320px;
  min-width: 320px;
  height: calc(100vh - var(--header-height) - var(--footer-height));
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.search-panel .form_wrapper {
  padding: 10px;
  padding-top: 0px;
  overflow-y: scroll;
}

.search-panel hr {
  width: 100%;
}

.search-panel .search_button {
  margin-bottom: 1em;
  text-align: right;
  display: none;
}
.search-panel .search_button input {
  box-sizing: border-box;
  display: inline-block;
  line-height: 0.7em;
  vertical-align: middle;
  border-radius: 3px;
  padding: 0.5rem 0rem;
  font-size: 0.85em;
  width: 80px;
  color: rgb(255, 255, 255);
  background-color: rgb(196, 94, 74);
  border: 1px solid rgb(196, 94, 74);
  cursor: pointer;
}

.search-panel form[name="search_form2"] {
  margin-top: 0.5em;
}

.search-panel .open_detail_button_area {
  position: relative;
  text-align: right;
}
.search-panel .open_detail_button {
  background-color: rgb(255, 255, 255);
  border: none;
  font-size: 1.2em;
  color: rgb(0, 0, 0);
  height: 1.8em;
  letter-spacing: .1em;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0.5em;
}
.search-panel .open_detail_button > span {
  /* +-ボタンを黒にする。 */
  filter: brightness(0);
}

.search-panel-area {
  display: flex;
  position: fixed;
  z-index: calc(var(--header-z-index) - 1);  /* ヘッダーよりz-indexを下げる。 */
  transition: all 0.4s;
}
.search-panel-area.opened {
  left: 0;
}
.search-panel-area.closed {
  left: -320px;
}
.search-panel-area .search-panel {
  background-color: #f2f2f2;
}

.search-panel-area .open_close_panel {
  margin: auto;
  border-radius: 12px;
  border: 0;
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
  width: 20px;
  height: 36px;
  cursor: pointer;
  border-left: 1px solid #dadce0;
  border-radius: 0 8px 8px 0;
  background: #fff 7px center/7px 10px no-repeat;
  display: flex;
  align-items: center;
}
.search-panel-area .open_close_panel svg {
  width: 16px;
  height: 16px;
  opacity: 1;
}
.search-panel-area .open_close_panel svg polygon {
  fill: #4B4B4B;
}

.search-panel-area .search-panel-balloon {
  position: relative;
  background-color: #333F50;
  color: white;
  border-radius: 10px;
  padding: 10px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  margin: auto;
  margin-left: 18px;
  font-weight: normal;
}
.search-panel-area .search-panel-balloon.fadein {
  animation: fadein-anim 0.5s forwards;
}
.search-panel-area .search-panel-balloon.fadeout {
  animation: fadeout-anim 0.5s forwards;
}

.search-panel-area .search-panel-balloon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -15px; /* 足の位置を調整 */
  border-width: 10px 15px 10px 0;
  border-style: solid;
  border-color: transparent #333F50 transparent transparent;
  transform: translateY(-50%);
}

@keyframes fadein-anim {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeout-anim {
  from { opacity: 1; }
  to { opacity: 0; }
}

@media screen and (max-height: 600px) {
  .search-panel {
    font-size: 0.9em;
  }
  .search-panel .open_detail_button {
    height: 2.2em;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  .search-panel {
    width: 250px;
    min-width: 250px;
  }

  .search-panel-area.closed {
    left: -250px;
  }
  .search-panel-area.opened {
    left: 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 3), screen and (min-resolution: 3dppx) {
  .search-panel-area .open_close_panel {
    width: 24px;
  }
}
