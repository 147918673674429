#ManagedReitType {
  width: 128px;
}
#ManagedReitType.en {
  width: 128px;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #ManagedReitType {
    width: 99px;
  }
  #ManagedReitType.en {
    width: 99px;
  }
}
