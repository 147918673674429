#MapPage .info_window {
  line-height: 1.5em;
}

#MapPage .info_window .window_header {
  display: flex;
  justify-content: right;
}
#MapPage .info_window .window_header .close_button {
  cursor: pointer;
}
#MapPage .info_window .window_header .close_button polygon, .close_button path {
  fill: rgb(75, 75, 75);
}
#MapPage .info_window .window_header .checkbox {
  margin-top: -2px;
}

#MapPage .info_window .content {
  display: flex;
  font-size: 0.85em;
  /* width: 460px; */
  /* height: 220px; */
  /* margin-top: 10px; */
}
#MapPage .info_window .content.has_note_cap_rate {
  min-width: 300px;
}
#MapPage .info_window .content .left {
  /* min-width: 220px; */
  max-width: 250px;
}
#MapPage .info_window .content .left .property_name {
  font-size: 1.125em;
  margin-bottom: 10px;
}
#MapPage .info_window .content img {
  max-height: 180px;
  max-width: 220px;
}
#MapPage .info_window .content .right {
  padding-left: 1em;
}

#MapPage .info_window .content .note {
  position: relative;
}
#MapPage .info_window .content .note .inner {
  border-bottom: 1px solid rgb(68, 84, 106);
  font-size: 0.9em;
}
#MapPage .info_window .content .note:hover .balloon {
  display: inline;
}
#MapPage .info_window .content .note .balloon  {
  display: none;
  position: absolute;
  padding: 6px;
  background-color: rgb(68, 84, 106);
  width: 180px;
  left: -8px;
  top : 100%;
  margin-top : 12px;
  color: white;
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.5em;
}
#MapPage .info_window .content .note .balloon:after {
  border-bottom: 12px solid rgb(68, 84, 106);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -12px;
  left : 5%;
  content: "";
  position: absolute;
}

#MapPage .info_window .ownership_form {
  display: flex;
}

#MapPage .info_window footer {
  margin-top: 3px;
  display: flex;
  justify-content: flex-end;
}
#MapPage .info_window footer .footer_button {
  background-color: #c45e4a;
  border: 1px solid #c45e4a;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.85em;
  line-height: 0.7em;
  padding: 0.5em;
  vertical-align: middle;
  /* width: 140px; */
  margin-left: 10px;
  font-weight: normal;
}
#MapPage .info_window footer .footer_button:first-child {
  margin-left: 0px;
}
#MapPage .info_window footer button.nippon_building {
  background-color: #80350E;
  border: 1px solid #80350E;
}

/**********************************
 * GoogleMapのスタイルを上書きする。
 **********************************/
 /* 吹き出しの×ボタンを非表示にする。(将来的にこのクラス名が変更されてしまい、有効にならなくなる可能性あり) */
.gm-ui-hover-effect {
  display: none !important;
}
/* 吹き出しのチェックボックスの位置を右上橋に寄るようにするため。 */
.gm-style-iw-d {
  overflow: initial !important;
  padding-right: 12px;
  padding-bottom: 10px;
}


@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #MapPage .info_window .content img {
    max-height: 108px;
    max-width: 132px;
  }
  #MapPage .info_window .content .left .property_name {
    font-size: 1.325em;
  }
  #MapPage .info_window .content.has_note_cap_rate {
    min-width: 265px;
  }
  #MapPage .info_window .content .left .property_name {
    font-size: 1.325em;
  }  
}
