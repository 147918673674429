#ValueMapPage .info_window {
  line-height: 1.5em;
}

#ValueMapPage .info_window .window_header {
  display: flex;
  justify-content: right;
}
#ValueMapPage .info_window .window_header .close_button {
  cursor: pointer;
}
#ValueMapPage .info_window .window_header .close_button polygon, .close_button path {
  fill: rgb(75, 75, 75);
}
#ValueMapPage .info_window .window_header .checkbox {
  margin-top: -2px;
}

#ValueMapPage .info_window .content {
  display: flex;
  font-size: 0.85em;
  width: initial !important;
  flex-direction: column;
}

#ValueMapPage .info_window .content .property_name {
  font-size: 1.125em;
  margin-bottom: 10px;
}

#ValueMapPage .info_window .content .data {
  display: flex;
}

#ValueMapPage .info_window .content .data_content {
  padding: 2px 0;
}

#ValueMapPage .info_window .content .data_content_restricted {
  text-align: center;
  padding: 2px 12px;
  color: white;
  background: #4E95D9;
  border-radius: 9px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#ValueMapPage .info_window .ownership_form {
  display: flex;
}

#ValueMapPage .info_window footer {
  margin-top: 3px;
  display: flex;
  justify-content: flex-end;
}
#ValueMapPage .info_window footer .footer_button {
  background-color: #c45e4a;
  border: 1px solid #c45e4a;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.85em;
  line-height: 0.7em;
  padding: 0.5em;
  vertical-align: middle;
  /* width: 140px; */
  margin-left: 10px;
  font-weight: normal;
}
#ValueMapPage .info_window footer .footer_button:first-child {
  margin-left: 0px;
}


/**********************************
 * GoogleMapのスタイルを上書きする。
 **********************************/
 /* 吹き出しの×ボタンを非表示にする。(将来的にこのクラス名が変更されてしまい、有効にならなくなる可能性あり) */
.gm-ui-hover-effect {
  display: none !important;
}
/* 吹き出しのチェックボックスの位置を右上橋に寄るようにするため。 */
.gm-style-iw-d {
  overflow: initial !important;
  padding-right: 12px;
  padding-bottom: 10px;
}


@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #ValueMapPage .info_window .content img {
    max-height: 108px;
    max-width: 132px;
  }
  #ValueMapPage .info_window .content .left .property_name {
    font-size: 1.325em;
  }
}
