#StockChart {
  margin-top: var(--header-height);
  margin-bottom: var(--footer-height);
  width: 100%;
  padding: 0 120px;
  overflow-x: hidden;
}


#StockChart .content .chart_tab {
  line-height: 32px;
  margin-top: 10px;
  padding: 3px;
  border: 1px solid rgb(204, 204, 204);
  overflow-wrap: break-word;
}

#StockChart .content .chart {
  margin-top: 10px;
  border: 1px solid rgb(204, 204, 204);
}
#StockChart .content .chart:last-child {
  margin-top: 50px;
  margin-bottom: 20px;
}

#StockChart .content .chart .title {
  text-align: center;
  font-weight: normal;
  font-size: 1.2em;
}

#StockChart .content input[type=radio] {
  display: none;
}
#StockChart .content label {
  font-size: 0.85em;
  margin: 0px 2px;
  padding: 5px 8px;
  cursor: pointer;
  border-radius: 9px;
  white-space: nowrap;
}
#StockChart .content label.checked {
  background-color: #D7D5D5;
}
#StockChart .content label[for=noi_y] {
  padding: 5px 16px;
}
#StockChart .content label[for=occ].ja {
  padding: 5px 25px;
}
#StockChart .content label[for=occ].en {
  padding: 5px 12px;
}
#StockChart .content label[for=rent] {
  padding: 5px 30px;
}

#StockChart .content button {
  background-color: #c45e4a;
  border: 1px solid #c45e4a;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 0.85em;
  line-height: 1;
  padding: 0.5rem 0;
  vertical-align: middle;
  width: 100px;
  margin-left: 20px;
}

#StockChart .form_wrapper {
  margin: 20px 10px -5px 10px;
}
#StockChart .form_wrapper form[name="search_form2"] {
  display: flex;
}
#StockChart .form_wrapper form[name="search_form2"] .search_button {
  display: none;
}

#StockChart .chart select {
  border: none;
  border-radius: 6px;
  height: 2em;
  font-size: .85em;
  font-weight: 400;
  background-color: var(--bg-color-estate);
}

#StockChart .chart .yChange {
  padding-left: 10px;
}

#StockChart .chart .xChange {
  text-align: center;
  margin: 0 0 10px 0;
}
#StockChart .chart .xChange option {
  font-weight: 400;
}

#StockChart .chart .y_slider_and_scatter {
  position: relative;
  display: flex;
  align-items: center;
}
#StockChart .chart .y_slider_and_scatter .y_range_slider {
  position: absolute;
  top: 50px;
  left: 20px;
}
#StockChart .chart .y_slider_and_scatter .scatter {
  width: 100%;
}
#StockChart .chart .x_range_slider {
  padding: 0 20px 0px 80px;
  margin: auto;
}

#StockChart .chart .rs-slider * {
  outline: none;
}

/***************************************** 
  RangeSliderのCSSに必要なものだけを転載。 
******************************************/
:root,
.rs-theme-light {
  --rs-slider-bar: #f2f2f5;
  --rs-slider-hover-bar: #e5e5ea;
  /* --rs-slider-thumb-border: #3498ff; */
  --rs-slider-thumb-border: #7F7F7F;
  --rs-slider-thumb-bg: #fff;
  /* --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25); */
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(127, 127, 127, 0.25);
  /* --rs-slider-progress: #3498ff; */
  --rs-slider-progress: #b3b3b3;
  --rs-tooltip-bg: #272c36;
  --rs-tooltip-text: #fff;
}
.rs-theme-dark {
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #34c3ff;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 195, 255, 0.25);
  --rs-slider-progress: #34c3ff;
  --rs-tooltip-bg: #5c6066;
  --rs-tooltip-text: #fff;
}
.rs-theme-high-contrast {
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #ffff00;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(255, 255, 0, 0.25);
  --rs-slider-progress: #ffff00;
  --rs-tooltip-bg: #1a1d24;
  --rs-tooltip-text: #fff;
}

.rs-slider {
  position: relative;
}
.rs-slider .rs-tooltip {
  display: none;
}
.rs-slider .rs-tooltip.rs-tooltip-placement-top .rs-tooltip::after {
  margin: auto;
  left: 0;
  right: 0;
}
.rs-slider-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.rs-slider-disabled .rs-slider-bar,
.rs-slider-disabled .rs-slider-handle::before {
  cursor: not-allowed;
}
.rs-slider-with-mark:not(.rs-slider-vertical) {
  margin-bottom: 29px;
}
.rs-slider-bar {
  height: 6px;
  border-radius: 3px;
  background-color: #f2f2f5;
  background-color: var(--rs-slider-bar);
  -webkit-transition: background-color ease-in-out 0.3s;
  transition: background-color ease-in-out 0.3s;
  cursor: pointer;
}
.rs-slider:hover .rs-slider-bar {
  background-color: #e5e5ea;
  background-color: var(--rs-slider-hover-bar);
}
.rs-slider-vertical .rs-slider-bar {
  height: 100%;
  width: 6px;
}
.rs-slider-handle {
  position: absolute;
  /* top: -50%; */
  top: -2.5px;
}
.rs-slider-handle::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #3498ff;
  border: 2px solid var(--rs-slider-thumb-border);
  background-color: #fff;
  background-color: var(--rs-slider-thumb-bg);
  margin-left: -6px;
  cursor: pointer;
  /* stylelint-disable */
  -webkit-transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  /* stylelint-enable */
  margin-top: -1px;
}
.rs-slider-handle:hover::before,
.rs-slider-handle:focus::before {
  -webkit-box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
  -webkit-box-shadow: var(--rs-slider-thumb-hover-shadow);
          box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
          box-shadow: var(--rs-slider-thumb-hover-shadow);
}
.rs-slider-handle:active::before,
.rs-slider-handle.active::before {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.rs-slider-vertical .rs-slider-handle {
  top: unset;
}
.rs-slider-vertical .rs-slider-handle::before {
  left: 3px;
  margin-top: -6px;
}
.rs-slider-handle:hover .rs-tooltip,
.rs-slider-handle.active .rs-tooltip {
  display: block;
  opacity: 1;
  top: -30px;
}
.rs-slider-vertical .rs-slider-handle:hover .rs-tooltip,
.rs-slider-vertical .rs-slider-handle.active .rs-tooltip {
  top: -33px;
  margin-left: 3px;
}
.rs-slider-mark {
  position: absolute;
  top: 15px;
  left: -2px;
  white-space: nowrap;
}
.rs-slider-mark-content {
  margin-left: -50%;
}
.rs-slider-mark-last {
  left: auto;
  right: -2px;
}
.rs-slider-mark-last .rs-slider-mark-content {
  margin-left: 50%;
}
.rs-slider-graduator {
  width: 100%;
}
.rs-slider-graduator ol,
.rs-slider-graduator li {
  list-style: none;
}
.rs-slider-graduator > ol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  width: 100%;
}
.rs-slider-graduator > ol > li {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 1%;
          flex: 1 1 1%;
  position: relative;
}
.rs-slider-graduator > ol > li:last-child::after,
.rs-slider-graduator > ol > li::before {
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  background-color: var(--rs-slider-thumb-bg);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #f2f2f5;
  border: 2px solid var(--rs-slider-bar);
  margin-left: -4px;
  top: -1px;
}
.rs-slider-vertical .rs-slider-graduator > ol > li:last-child::after,
.rs-slider-vertical .rs-slider-graduator > ol > li::before {
  top: unset;
  bottom: -4px;
  margin-left: -1px;
}
.rs-slider-graduator > ol > li:last-child::after {
  right: -4px;
}
.rs-slider-vertical .rs-slider-graduator > ol > li:last-child::after {
  left: 0;
  bottom: unset;
  top: -4px;
}
.rs-slider-graduator > ol > li.rs-slider-pass::before {
  border-color: #3498ff;
  border-color: var(--rs-slider-progress);
}
.rs-slider-graduator > ol > li.rs-slider-active::before {
  visibility: hidden;
}
.rs-slider-vertical .rs-slider-graduator {
  display: block;
  height: 100%;
}
.rs-slider-vertical .rs-slider-graduator > ol {
  width: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  height: 100%;
  padding: 0;
}
.rs-slider-vertical .rs-slider-graduator > ol > li {
  display: block;
  padding: 0;
}
.rs-slider-progress-bar {
  position: absolute;
  /* height: 2px; */
  height: 6px;
  border-radius: 3px 0 0 3px;
  background-color: #3498ff;
  background-color: var(--rs-slider-progress);
}
.rs-slider-vertical .rs-slider-progress-bar {
  /* width: 2px; */
  width: 6px;
  border-radius: 0 0 3px 3px;
}
.rs-slider-vertical {
  height: 100%;
}
.rs-slider-vertical .rs-slider-mark {
  top: unset;
  bottom: -8px;
  left: 15px;
}
.rs-slider-vertical .rs-slider-mark-content {
  margin-left: auto;
}
.rs-slider-vertical .rs-slider-mark-last {
  bottom: unset;
  top: -8px;
}

.rs-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 12px;
  opacity: 0;
  line-height: 1.66666667;
  max-width: 250px;
  padding: 2px 10px;
  color: #fff;
  color: var(--rs-tooltip-text);
  text-align: center;
  background-color: #272c36;
  background-color: var(--rs-tooltip-bg);
  border-radius: 4px;
  overflow-wrap: break-word;
}
.rs-tooltip.rs-anim-fade {
  -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
  transition: opacity 0.1s linear, transform 0.1s ease-out;
  transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
}
.rs-tooltip.rs-anim-in {
  opacity: 1;
  -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
  transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
  transition: opacity 0.15s linear, transform 0.15s ease-in;
  transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
}
.rs-tooltip-arrow::before,
.rs-tooltip-arrow::after {
  content: ' ';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rs-tooltip-arrow::before {
  display: none;
}
.rs-theme-high-contrast .rs-tooltip {
  border: 1px solid #e5e5ea;
  border: 1px solid var(--rs-border-primary);
}
.rs-theme-high-contrast .rs-tooltip-arrow::before {
  display: block;
}
.rs-tooltip[class*='placement-top'] {
  margin-top: -8px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.rs-tooltip[class*='placement-top'].rs-anim-in {
  -webkit-transform: translate(0, -2px);
          transform: translate(0, -2px);
}
.rs-tooltip[class*='placement-top']::after {
  bottom: -6px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #272c36;
  border-top-color: var(--rs-tooltip-bg);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-top']::after {
    bottom: -7px;
  }
}
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-top']::after {
    bottom: -7px;
  }
}
.rs-theme-high-contrast .rs-tooltip[class*='placement-top']::before {
  bottom: -7px;
  margin-left: -7px;
  border-width: 7px 7px 0;
  border-top-color: var(--rs-tooltip-border);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-top']::before {
    bottom: -8px;
  }
}
@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-top']::before {
    bottom: -8px;
  }
}
.rs-tooltip[class*='placement-bottom'] {
  margin-top: 8px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.rs-tooltip[class*='placement-bottom'].rs-anim-in {
  -webkit-transform: translate(0, 2px);
          transform: translate(0, 2px);
}
.rs-tooltip[class*='placement-bottom']::after {
  top: -6px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #272c36;
  border-bottom-color: var(--rs-tooltip-bg);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-bottom']::after {
    top: -7px;
  }
}
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-bottom']::after {
    top: -7px;
  }
}
.rs-theme-high-contrast .rs-tooltip[class*='placement-bottom']::before {
  top: -7px;
  margin-left: -7px;
  border-width: 0 7px 7px;
  border-bottom-color: var(--rs-tooltip-border);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-bottom']::before {
    top: -8px;
  }
}
@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-bottom']::before {
    top: -8px;
  }
}
/* rtl:begin:ignore */
.rs-tooltip[class*='placement-right'] {
  margin-left: 8px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.rs-tooltip[class*='placement-right'].rs-anim-in {
  -webkit-transform: translate(2px, 0);
          transform: translate(2px, 0);
}
.rs-tooltip[class*='placement-right']::after {
  left: -6px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #272c36;
  border-right-color: var(--rs-tooltip-bg);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-right']::after {
    left: -7px;
  }
}
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-right']::after {
    left: -7px;
  }
}
.rs-theme-high-contrast .rs-tooltip[class*='placement-right']::before {
  left: -7px;
  margin-top: -7px;
  border-width: 7px 7px 7px 0;
  border-right-color: var(--rs-tooltip-border);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-right']::before {
    left: -8px;
  }
}
@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-right']::before {
    left: -8px;
  }
}
.rs-tooltip[class*='placement-left'] {
  margin-left: -8px;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.rs-tooltip[class*='placement-left'].rs-anim-in {
  -webkit-transform: translate(-2px, 0);
          transform: translate(-2px, 0);
}
.rs-tooltip[class*='placement-left']::after {
  right: -6px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #272c36;
  border-left-color: var(--rs-tooltip-bg);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-tooltip[class*='placement-left']::after {
    right: -7px;
  }
}
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*='placement-left']::after {
    right: -7px;
  }
}
.rs-theme-high-contrast .rs-tooltip[class*='placement-left']::before {
  right: -7px;
  margin-top: -7px;
  border-width: 7px 0 7px 7px;
  border-left-color: var(--rs-tooltip-border);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-left']::before {
    right: -8px;
  }
}
@supports (-ms-ime-align: auto) {
  .rs-theme-high-contrast .rs-tooltip[class*='placement-left']::before {
    right: -8px;
  }
}
/* rtl:end:ignore */
.rs-tooltip.placement-bottom::before,
.rs-tooltip.placement-top::before,
.rs-tooltip.placement-bottom::after,
.rs-tooltip.placement-top::after {
  left: 50%;
}
.rs-tooltip.placement-bottom-start::before,
.rs-tooltip.placement-top-start::before,
.rs-tooltip.placement-bottom-start::after,
.rs-tooltip.placement-top-start::after {
  left: 10px;
}
.rs-tooltip.placement-bottom-end::before,
.rs-tooltip.placement-top-end::before {
  right: 3px;
}
.rs-tooltip.placement-bottom-end::after,
.rs-tooltip.placement-top-end::after {
  right: 4px;
}
.rs-tooltip.placement-right::before,
.rs-tooltip.placement-left::before,
.rs-tooltip.placement-right::after,
.rs-tooltip.placement-left::after {
  top: 50%;
}
.rs-tooltip.placement-right-start::before,
.rs-tooltip.placement-left-start::before,
.rs-tooltip.placement-right-start::after,
.rs-tooltip.placement-left-start::after {
  top: 10px;
}
.rs-tooltip.placement-right-end::before,
.rs-tooltip.placement-left-end::before {
  bottom: 3px;
}
.rs-tooltip.placement-right-end::after,
.rs-tooltip.placement-left-end::after {
  bottom: 4px;
}

/* 以下は少し改造して転載。 */
.rs-slider-handle:after, .rs-slider-handle:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and ( max-width: 1080px ) {
  #StockChart {
    padding: 0 10px;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5), screen and (min-resolution: 1.5dppx) {
  #StockChart .content .chart .title {
    font-size: 1.5em;
  }

  #StockChart .chart select {
    font-size: 1.2em;
  }
}
